import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { store, persistor } from './store/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { PersistGate } from 'redux-persist/integration/react'
import GlobalStyles from './styles/global'
import { ThemeProvider } from 'styled-components'
import api from './utils/api'
import { postRefreshToken, logOut } from './containers/LoginPage/reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { IconContext } from 'react-icons'
import GWLOGO from './assets/images/gamewallet.png'
import GWLOGOICON from './assets/images/gamewalleticon-50.png'
import SRPAYLOGO from './assets/images/SRPAY_LOGO.png'
import { Helmet } from 'react-helmet'
import { ConfigProvider } from 'antd'

const GW = {
  main: '#91C431',
  logo: GWLOGO,
  logoIcon: GWLOGOICON,
  title: 'Game Wallet'
} as const

const SRP = {
  main: '#2196f3',
  logo: SRPAYLOGO,
  logoIcon: SRPAYLOGO,
  title: 'SRPay'
} as const

const isGWorSRP = window.location.hostname.toLowerCase().includes('srpaygateway')
export const customTheme = isGWorSRP ? SRP : GW

const theme = {
  colors: {
    primaryBackground: '#f6f9fc',
    primaryColor: customTheme.main,
    secondaryColor: '#fff',
    errorColor: '#f5222d',
    enableColor: '#4dbd74',
    disableColor: '#f86c6b',
    inActiveColor: '#c8ced3',
    labelBackground: '#f2f2f2'
  },
  statusTextColors: {
    FAILED: '#f5222d',
    SUCCESS: '#00ab3b',
    PENDING: '#20a8d8'
  },
  statusBackgroundColors: {
    FAILED: '#ffe0e2',
    SUCCESS: '#dfffea',
    PENDING: '#d5f4ff'
  }
}

let isRefreshing = false
let failedQueue: any[] = []
function addFailedQueue (callback: any): void {
  failedQueue.push(callback)
}

api.interceptors.response.use(response => {
  return response
}, async (error: any): Promise<any> => {
  const { config, response: { status } } = error
  const originalRequest = config
  originalRequest._retry = false
  if (status === 401 && originalRequest._retry === false) {
    const retryOrigReq = new Promise((resolve) => {
      addFailedQueue((authToken: string) => {
        originalRequest.headers.Authorization = 'Bearer ' + authToken
        resolve(api(originalRequest))
      })
    })
    if (!isRefreshing) {
      isRefreshing = true
      const { authReducer: { authToken, refreshToken } } = store.getState()
      try {
        const tokenPayload = await store.dispatch(postRefreshToken({ authenticationToken: authToken, refreshToken })).then(unwrapResult)
        isRefreshing = false
        failedQueue.forEach(prom => prom(tokenPayload.authToken))
        failedQueue = []
      } catch (e) {
        await persistor.purge()
        store.dispatch(logOut())
        return window.location.reload()
      }
    }

    return await retryOrigReq
  }

  return await Promise.reject(error)
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ConfigProvider theme={{ token: { colorPrimary: customTheme.main } }}>
            <GlobalStyles theme={theme} />
            <IconContext.Provider value={{ size: '16px', style: { verticalAlign: 'middle' } }}>
              <Helmet>
                {isGWorSRP ? (
                  <link rel='icon' type='image/png' href='srpayfavicon.ico' sizes='16x16' />
                ) : <link rel='icon' type='image/png' href='favicon.ico' sizes='16x16' />}
              </Helmet>
              <App />
            </IconContext.Provider>
          </ConfigProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
